<template>
  <v-autocomplete
    v-model="timeZoneId"
    :items="timeZoneOptions"
    outlined
    label="Time Zone"
    item-text="name"
    item-value="id"
  >
  </v-autocomplete>
</template>

<script>
import appStore from '@/modules/app/store';
import { computed, defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    value: Number,
  },
  setup(props, { emit }) {
    const timeZoneOptions = computed(() => appStore.state.timeZones.map(tz => ({ name: tz.name, id: tz.id })))

    const timeZoneId = computed({
      get: () => props.value,
      set: value => emit('update', value),
    })

    return {
      timeZoneOptions,
      timeZoneId,
    }
  },
})
</script>
