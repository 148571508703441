<template>
    <div>
      <template>
        <div
          v-for="[key, value] in Object.entries(value)"
          :key="key"
        >
          <b>{{ key }}</b>: <span>{{ value }}</span>
          <v-icon
            size="16"
            class="cursor-pointer"
            @click="removeKey(key)"
          >
            {{ icons.mdiDelete }}
          </v-icon>
        </div>
      </template>
      <v-btn
        v-show="!adding"
        @click="adding = true"
      >
        {{ btnText }}
      </v-btn>
  
      <template v-if="adding">
        <v-text-field
          v-model="key"
          placeholder="key name"
        />
        <v-text-field
          v-model="field"
          placeholder="value"
        />
        <v-btn
          color="primary"
          class="mr-2"
          @click="updateKeyValue"
        >
          OK
        </v-btn>
        <v-btn
          @click="reset"
        >
          CANCEL
        </v-btn>
      </template>
    </div>
  </template>
  
  <script>
  import { mdiDelete } from '@mdi/js';
import { ref } from '@vue/composition-api';
  
  export default {
    props: {
      value: {
        type: Object,
        default: () => {},
      },
      btnText: {
        type: String,
        default: 'Add a new key value ...',
      },
    },
    setup(props, { emit }) {
      const adding = ref(false)
      const key = ref('')
      const field = ref('')
  
      const reset = () => {
        key.value = ''
        field.value = ''
        adding.value = false
      }
  
      const updateKeyValue = () => {
        const newModelValue = { ...props.value }
        newModelValue[key.value] = field.value
        emit('input', newModelValue)
        reset()
      }
  
      const removeKey = k => {
        const newModelValue = { ...props.value }
        delete newModelValue[k]
        console.log('new key value', newModelValue)
        emit('input', newModelValue)
        reset()
      }
  
      return {
        key,
        field,
        adding,
        updateKeyValue,
        removeKey,
        reset,
        icons: {
          mdiDelete,
        },
      }
    },
  }
  </script>
  