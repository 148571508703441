import { storePostMedia } from '@/api/common/'
import { notifyErrors } from '@/assets/js/utils'

export default class UploadAdapter {
  constructor(loader, options) {
    // The file loader instance to use during the upload.
    this.loader = loader
    this.uploadUrl = options.uploadUrl // /merchant/post-media/photos
    this.postUuid = options.postUuid // uuid of the post, (like post_uuid in product)
  }

  // Starts the upload process.
  upload() {
    return this.loader.file.then(file => {
      const data = new FormData()
      data.append('post_uuid', this.postUuid)
      data.append('file', file)

      return storePostMedia(this.uploadUrl, data)
        .then(res => {
          const media = res.data.data

          return Promise.resolve({
            default: media.instance.detail,
          })
        })
        .catch(err => {
          notifyErrors(err)

          return Promise.reject(err)
        })
    })
  }

  // Aborts the upload process.
  abort() {
    if (this.xhr) {
      this.xhr.abort()
    }
  }

  // Initializes the XMLHttpRequest object using the URL passed to the constructor.
  // initRequest() {
  //   const xhr = this.xhr = new XMLHttpRequest()

  //   xhr.open('POST', this.uploadUrl, true)
  //   xhr.responseType = 'json'
  // }

  // Initializes XMLHttpRequest listeners.
  // initListeners(resolve, reject, file) {
  //   const { xhr } = this
  //   const { loader } = this
  //   const genericErrorText = `Couldn't upload file: ${file.name}.`

  //   xhr.addEventListener('error', () => reject(genericErrorText))
  //   xhr.addEventListener('abort', () => reject())
  //   xhr.addEventListener('load', () => {
  //     const { response } = xhr

  //     // console.log('upload response', response)

  //     if (!response || response.error) {
  //       return reject(response && response.error ? response.error.message : genericErrorText)
  //     }

  //     resolve({
  //       default: response.url,
  //     })
  //   })

  //   if (xhr.upload) {
  //     xhr.upload.addEventListener('progress', evt => {
  //       if (evt.lengthComputable) {
  //         loader.uploadTotal = evt.total
  //         loader.uploaded = evt.loaded
  //       }
  //     })
  //   }
  // }

  // Prepares the data and sends the request.
  // sendRequest(file) {
  //   // Prepare the form data.
  //   const data = new FormData()

  //   data.append('file', file)

  //   // Send the request.
  //   this.xhr.send(data)
  // }
}
